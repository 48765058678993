import { t } from "../../../f"

export const fetchQuotationData = async (api, lang) => {
  const { results: quotations } = await api('ListQuotation')

  return quotations.map(({
    itinerary,
    situation,
    ...rest
  }) => ({
    ...rest,
    itinerary: {
      ...itinerary,
      name: `${itinerary.code} - ${itinerary.name}`,
      id: itinerary.id,
    },
    situation: {
      code: situation.name,
      name: t(lang.situations, situation.name),
      id: situation.id,
    },
  }))
}
