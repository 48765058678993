import { AppContext } from "../../App";
import { useContext, useMemo, useState, useCallback, useEffect } from "react";
import { Frame } from '../../c'
import { QuotationTable } from "./components/QuotationTable";
import { fetchQuotationData } from "./useCases/fetchQuotationData";
import { ObservacaoModal } from "./components/Modals/ObservacaoModal";
import { CancelModal } from "./components/Modals/CancelModal";
import { BidModal } from "./components/Modals/BidModal";
import { CreateModal } from "./components/Modals/CreateModal";
export const Bid = ({ setReloader }) => {
  const App = useContext(AppContext)

  const lang = useMemo(() => {
    const { lang: { global, bid } } = App

    return { ...global, ...bid }
  }, [App])

  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [editing, setEditing] = useState(false)
  const [displayCreateModal, setDisplayCreateModal] = useState(false)

  const Modal = useMemo(() => {
    if (editing.op === 'observacao') return ObservacaoModal

    if (editing.op === 'cancel') return CancelModal

    if (editing.op === 'lance') return BidModal

    if (editing.op === 'transportadora') return BidModal

    return CancelModal
  }, [editing?.op])

  const getRows = useCallback(async () => {
    try {
      setLoading(true)
      const fetchedRecords = await fetchQuotationData(App.api, lang)

      setRecords(fetchedRecords)

    } catch (error) {
      setRecords([])
    } finally {
      setLoading(false)
    }
  }, [App.api, lang])

  const handleModal = useCallback((tab) => {
    setEditing(tab)
  }, [])

  const handleCloseModal = useCallback(() => {
    setEditing(false)
  }, [setEditing])

  const handleFinishModal = useCallback(async () => {
    setEditing(false)
    await getRows()
  }, [getRows])

  const handleCloseCreateModal = useCallback(() => {
    setDisplayCreateModal(false);
  }, [])

  const handleFinishCreateModal = useCallback(() => {
    setDisplayCreateModal(false);
    getRows();
  }, [getRows])

  const handleReloadRecords = useCallback(() => {
    getRows();
  }, [getRows])

  useEffect(() => {
    getRows()
  }, [getRows])

  useEffect(() => {
    if(setReloader) {
      setReloader(() => {
        return () => {
          getRows()
        }
      })
    }
  }, [getRows, setReloader])

  return (
    <Frame
      flex
      className="quotation"
      title={'Cotações'}
      loading={loading}
      actions={{
        add: App.user.in_uorgadm === 'T'
          ? () => { setDisplayCreateModal(true) }
          : null,
      }}
    >

      <QuotationTable
        records={records}
        setEditing={handleModal}
        onRelaod={handleReloadRecords}
      />

      { editing && (
        <Modal
          quotation={editing.quotation ?? {}}
          bid={editing.bid ?? {}}
          onClose={handleCloseModal}
          onFinish={handleFinishModal}
          App={App}
        />
      )}

      <CreateModal
        visible={displayCreateModal}
        lang={App.lang.bid.createModal}
        onClose={handleCloseCreateModal}
        onFinish={handleFinishCreateModal}
      />
    </Frame>
  );

};
