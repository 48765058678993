import { useState, useContext, useMemo, useCallback } from "react"

import { AppContext } from "../../../App";
import { DropdownMenu } from "../../../c";

import SITUATIONS from './contents/situations.json'
import {
  MdThumbUp as ApproveIcon,
  MdThumbDown as DisapproveIcon,
  MdOutlineTextSnippet as DocumentIcon,
  MdCancel as CancelIcon,
  MdPictureAsPdf as PdfIcon,
} from 'react-icons/md'

import {
  IoMdChatboxes as ObservationIcon
} from "react-icons/io"

import {
  IoShieldCheckmarkSharp as RequestIcon
} from "react-icons/io5"

export const DropdownEditMenu = ({ faturamento, control, setObservacao, onFinish, setViewDocument }) => {
  const App = useContext(AppContext);
  const lang = { ...App.lang.global, ...App.lang.produtos, ...App.lang.Obs};

  const [data, setData] = useState({ ...faturamento, NEWOBS: [] });

  const insertObs = useCallback(({ description, situation }) => {
    data?.NEWOBS.find((o) => o.op === situation) && data.NEWOBS.splice(
      data.NEWOBS.findIndex((o) => o.op === situation),
      1
    );

    data.NEWOBS.push({
      ID_OPR: App.user.id_usr,
      ID_LOGIN: App.user.id_login,
      DT_OPR: App.user.dt_sistema,
      DS_OBS: {
        ds_obs: description,
        id_color: [SITUATIONS['CANCELED'], SITUATIONS['DISAPPROVED']].includes(situation)
                ? "danger"
                : situation === SITUATIONS['IN_ANALYSIS']
                ? "warning"
                : "success",
      },
      op: situation,
      new: true,
      TP_OBSS: 'M'
    });
  }, [App.user.dt_sistema, App.user.id_login, App.user.id_usr, data.NEWOBS])

  const recusaEmail= useCallback(async () => {
    const result = await App.api(
      "faturamento::alterarFaturamento",
      { ID_UOCC: faturamento.ID_UOCC, situation: "INVOICED" }
    )

    onFinish()

    return result;
  }, [App, faturamento.ID_UOCC, onFinish])

  const confirmDialog = useCallback(() => {
    App.confirm(
      "Deseja enviar e-mail para processamento da fatura?",
      async () => {
        const result = await App.api(
          "faturamento::enviarEmail",
          { ID_UOCC: faturamento.ID_UOCC }
        )

        App.toast.success( "Email em processamento" );

        onFinish();

        return result;
      },
      '',
      recusaEmail // No caso de recusar envio do email
    )
  }, [App, faturamento.ID_UOCC, onFinish, recusaEmail])


  const handleRequestToApprove = useCallback(async () => {
    await App.api(
      "faturamento::alterarFaturamento",
      { ID_UOCC: faturamento.ID_UOCC, situation: "IN_ANALYSIS"}
    )
    await App.api("controllership::alter_invoice_situation", { invoice: faturamento.ID_UOCC })

    App.toast.success( "Sucesso" )
    onFinish()
  }, [App, faturamento.ID_UOCC, onFinish])

  const handleApprove = useCallback(async () => {
    await App.api("faturamento::aprovado", { ID_UOCC: faturamento.ID_UOCC })

    App.toast.success( "Solicitação do faturamento " + faturamento.ID_UOCC + " aprovada" );

    if(faturamento.FAT_STS === "4"){
      onFinish()
    }else{
      confirmDialog();
    }
  }, [App, confirmDialog, faturamento, onFinish])

  const handleDisapprove = useCallback(() => {
      insertObs({
        description: `${App.user.nm_prc} reprovou o faturamento.`,
        situation: SITUATIONS.DISAPPROVED
      });
      setData({ ...data });
      setObservacao(data);
  }, [App.user.nm_prc, data, insertObs, setObservacao])

  const handleCancel = useCallback(() => {
    insertObs({
      description: `${App.user.nm_prc} cancelou o faturamento.`,
      situation: SITUATIONS.CANCELED
    });
    setData({ ...data });
    setObservacao(data);
  }, [App.user.nm_prc, data, insertObs, setObservacao])

  const handleInvoiceSettlement = useCallback(async () => {
    const {allows_to_finish } = faturamento
    if(allows_to_finish) {
      await App.api(
        "faturamento::alterarFaturamento",
        { ID_UOCC: faturamento.ID_UOCC, situation: "FINISHED"}
      )
    }

    onFinish();
  },[App, faturamento, onFinish])

  const itens = useMemo(() => {
    const result = []

    if (faturamento.approvable) {
      result.push({
        title: 'Aprovar fatura',
        action: handleApprove,
        disabled: !faturamento.allows_to_approve,
        icon: <ApproveIcon style={{color: "var(--success)", marginRight: "2px"}}/>,
      });
    }

    if (faturamento.disapprovable) {
      result.push({
        title: 'Reprovar fatura',
        action: handleDisapprove,
        disabled: !faturamento.allows_to_disapprove,
        icon: <DisapproveIcon style={{color: "var(--danger)", marginRight: "2px"}}/>
      });
    }

    if (faturamento.requestable_to_approve) {
      result.push({
        title: 'Solicitar Aprovação',
        action: handleRequestToApprove,
        disabled: !faturamento.allows_to_request_approval,
        icon: <RequestIcon style={{color: "var(--info)", marginRight: "2px"}}/>
      });
    }

    if (faturamento.cancelable) {
      result.push({
        title: 'Cancelar',
        action: handleCancel,
        disabled: !faturamento.allows_to_cancel,
        icon: <CancelIcon style={{color: "var(--danger)", marginRight: "2px"}}/>
      })
    }

    if (faturamento.settlement_document) {
      result.push({
        title: 'Baixar fatura',
        action: handleInvoiceSettlement,
        disabled: !faturamento.allows_to_settlement,
        icon: <DocumentIcon style={{color: "var(--info)", marginRight: "2px"}}/>,
      });
    }

    if (faturamento.view_document) {
      result.push({
        title: 'Visualizar fatura',
        action: () => setViewDocument({tipo: 'fatura', idPrt: faturamento.portarias[0].id.idUocc}),
        disabled: !faturamento.allows_to_view,
        icon: <PdfIcon style={{color: "var(--info)", marginRight: "2px"}}/>,
      });
    }

    result.push({
      title: 'Observações',
      action: () => setObservacao(faturamento),
      disabled: faturamento.FAT_STS === "3",
      icon: <ObservationIcon style={{color: "var(--info)", marginRight: "2px"}}/>
    });

    return result
  }, [faturamento, handleApprove, handleCancel, handleDisapprove, handleInvoiceSettlement, handleRequestToApprove, setObservacao, setViewDocument])

  return (
    <div className="f" style={{ justifyContent: "end" }}>
      <DropdownMenu itens={itens} title={lang.acoes} style={{right: "calc(15%)"}}>
        {control}
      </DropdownMenu>
    </div>
  );
}
