import { formatMoney, formatNumber } from "../f"

const formats = {
  number: (value) => formatNumber(value, 0),
  money: (value) => formatMoney(value, 2),
}

const getFilters = ({
  data,
  field,
  filters,
  lang,
  onFilter,
  selectedFilter: { field: selectedField, value: selectedValue } = {},
  selectionType,
  value: groupValue,
}) => {
  return filters.map(({ action, format, label, value: searchValue }) => {
    const filterValue = selectionType === 'individual' ? searchValue : groupValue
    const active = selectedField === field && selectedValue === filterValue

    const collection = data.map((item) => {
      let value = 0
      let found = false

      if(selectionType === 'individual') {
        value = item[field]
        found = item[field] === searchValue
      }

      if(selectionType === 'group') {
        value = item[searchValue]
        found = item[field] === groupValue
      }

      return [value, found]
    })

    let total = 0
    let value = 0

    if(action === 'counter') {
      total = collection.length
      value = collection.filter(([, found]) => found).length
    } else if(action === 'sum') {
      total = collection.reduce((sum, [value]) => sum + value, 0);
      value = collection.reduce(
        (sum, [value, found]) => found ? sum + value : sum,
        0
      )
    }

    return {
      className: active ? 'active' : '',
      key: [field, groupValue, searchValue].filter((key) => key).join('-'),
      label: lang[label] ?? label,
      onFilter: () => onFilter(active ? {} : { field, value: filterValue }),
      ratio: formatNumber( (value ?? 0) / (total || 1) * 100, 1 ),
      value: formats[format](value),
    }
  })
}

export const getCardFilters = (CARDS, { data, lang, selectedFilter, onFilter }) => {
  return CARDS.map(({type, className, field, filters, label, selectionType, value }) => {
    if(type === 'divisor') return { type }

    return ({
      className,
      type,
      filters: getFilters({
        data,
        field,
        filters,
        lang,
        onFilter,
        selectedFilter,
        selectionType,
        value,
      }),
      key: selectionType === 'group' ? `${field}-${value}` : field,
      label: lang[label] ?? label,
    })
  })
}
