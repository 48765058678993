import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { renderToStaticMarkup } from 'react-dom/server';
import { MdFmdGood as PointIcon, MdAddLocation  as TollIcon } from "react-icons/md"
import { Map, GeoJSON, TileLayer, Marker, Popup } from "react-leaflet"
import Leaflet from "leaflet"

import { AppContext } from "../../../../../../App"
import { Input, Select } from "../../../../../../c"
import { formatMoney, t } from "../../../../../../f"

import { fetchMarkers } from "../useCases/fetchMarkers"

import INITIAL_MARKERS from '../datas/INITIAL_MARKERS.json'

export const ItineraryStep = ({ form: initialForm, lang, onFormChange }) => {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(true)
  const [itineraries, setItineraries] = useState([])
  const [form, setForm] = useState({
    itinerary: initialForm?.itinerary?.id
  })
  const [
    { destination, origin, reference, route, tolls, zoom, },
    setMarkers
  ] = useState(INITIAL_MARKERS)

  const getMarkerIcon = useCallback((kind) => {
    const [Icon, color] = {
      destination: [PointIcon, '#139248'],
      origin: [PointIcon, '#D04933'],
      toll: [TollIcon, '#4F2DE2'],
    }[kind] ?? [PointIcon, '#126ADD']

    const iconMarkup = renderToStaticMarkup(<Icon color={color} size={32} />);

    return new Leaflet.divIcon({
      className: 'no-style',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
      html: iconMarkup,
    });
  }, []);

  const handleChangeForm = useCallback((data) => {
    if(onFormChange) onFormChange(data)
  }, [onFormChange])

  const handleChange = useCallback((field) => {
    return (event) => {
      const { value } = event ?? {}

      setForm((prevState) => ({ ...prevState, [field]: value }))
    }
  }, [])

  const itineraryOptions = useMemo(() => {
    return itineraries
      .reduce((result, { code, id, name }) => {
        if(result.find(({ value }) => id === value)) return result

        return [...result, { label: `${code} - ${name}`, value: id }];
      }, [])
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [itineraries])

  const itinerary = useMemo(() => {
    return itineraries.find(({ id }) => id === form.itinerary)
  }, [form.itinerary, itineraries])

  useEffect(() => {
    (async() => {
      const { results } = await App.api('ListItinerary', { routeType: 'R' })

      setItineraries(results)
    })()
  },[App])

  useEffect(() => {
    if(initialForm?.itinerary?.id === form.itinerary) return

    handleChangeForm({ ...initialForm, itinerary })
  },[form.itinerary, handleChangeForm, initialForm, itinerary])

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)

        if(!itinerary) throw new Error('no-itinerary')

        if(1 === 0) { // TODO: escondendo o mapa temporariamente
          const {
            tollAmount: newTollAmount,
            duration: newDuration,
            distance: newDistance,
            ...newMarkers
          } = await fetchMarkers(itinerary)

          setMarkers((prevState) => ({ ...prevState, ...newMarkers }))
        }
      } catch (error) {
        if(itinerary){
          const message = typeof error === 'string' ? error : error.message
          const errorMessage = t(lang, message, {
            defaultValue: t(lang, 'errorFetchingItinerary')
          })

          App.toast.error(errorMessage)
        }

        setMarkers(INITIAL_MARKERS)
      } finally {
        setLoading(false)
      }
    })()
  }, [App.toast, handleChangeForm, initialForm, itinerary, lang])

  return (
    <div className="f g2 w100">
      <div className="f g1 f-column f1">
        <Select
          clearable
          searchable
          className="w100"
          defaultValue={form.itinerary}
          error={!form.itinerary}
          label={t(lang, 'itinerary')}
          onChange={handleChange('itinerary')}
          options={itineraryOptions}
          value={form.itinerary}
        />
        <Input
          disabled
          label={t(lang, 'toll')}
          value={formatMoney(itinerary?.tollAmount ?? 0, 2)}
        />
        <Input
          disabled
          label={t(lang, 'distance')}
          value={itinerary?.distance ?? ''}
        />
        <Input
          disabled
          label={t(lang, 'duration')}
          value={itinerary?.duration ?? ''}
        />
      </div>

      <div className="f2 radius overflow-hidden box-shadow itinerary-map" style={{ display: 'none' }}>
        {(loading || 1 !== 0) // TODO: escondendo o mapa temporariamente
          ? <App.LoadingComponent />
          : (
            <Map
              key={JSON.stringify([reference, origin, destination])}
              center={[reference.latitude, reference.longitude]}
              zoom={zoom}
              style={{ position: "relative", width: "100%", height: 375 }}
            >
              {origin && (
                <Marker
                  position={[origin.latitude, origin.longitude]}
                  icon={getMarkerIcon('origin')}
                >
                  <Popup>{t(lang, 'startRoute')}</Popup>
                </Marker>
              )}

              {destination && (
                <Marker
                  position={[destination.latitude, destination.longitude]}
                  icon={getMarkerIcon('destination')}
                >
                  <Popup>{t(lang, 'endRoute')}</Popup>
                </Marker>
              )}

              {route.length > 0 && (
                <GeoJSON
                  attribution={'&copy; <a href="https://velog.com.br">VELOG</a>'}
                  data={{
                    type: "RotaCollection",
                    features: [{
                      type: "Feature",
                      geometry: {
                        type: "LineString",
                        coordinates: route.map(({longitude, latitude}) => [longitude, latitude])
                      }
                    }],
                  }}
                />
              )}

              {tolls.map((toll, index) => (
                <Marker
                  icon={getMarkerIcon('toll')}
                  key={`toll-${toll.latitude}-${toll.longitude}-${index}`}
                  position={[toll.latitude, toll.longitude]}
                >
                  <Popup>
                    <div className="toll-icon-popup-container">
                      <div
                        className="toll-image-container"
                        style={{ backgroundImage: `url("${toll.image}")` }}
                      />
                      <span>{toll.place}</span>
                      <span>
                        <strong>{formatMoney(toll.tariff / 6, 2)}</strong>
                      </span>
                    </div>
                  </Popup>
                </Marker>
              ))}

              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
              />
            </Map>
          )
        }
      </div>
    </div>
  )
}
