import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import moment from "moment"

import { AppContext } from "../../../../../../App"
import { Input, Select } from "../../../../../../c"
import { t, formatNumber } from "../../../../../../f"

export const QuotationStep = ({ form: initialForm, lang, onFormChange }) => {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    startQuotation: initialForm?.startQuotation,
    endQuotation: initialForm?.endQuotation,
    product: initialForm?.product?.id,
    vehicleTypes: initialForm?.vehicleTypes?.map(({ id }) => id) ?? [],
    minimumVehicleQuantity: initialForm?.minimumVehicleQuantity ?? 0,
  })
  const [products, setProducts] = useState([])
  const [vehicleTypes, setVehicleTypes] = useState([])

  const startQuotationError = useMemo(() => {
    if(!form.startQuotation) return true

    if(form.startQuotation < moment().format('YYYY-MM-DDTHH:mm')) return true

    return  form.startQuotation > moment().add(30, 'days').format('YYYY-MM-DDTHH:mm')
  }, [form.startQuotation])

  const startQuotationReference = useMemo(() => {
    if(!form.startQuotation) return moment()

    return  moment(form.startQuotation)
  }, [form.startQuotation])

  const endQuotationError = useMemo(() => {
    if(!form.endQuotation) return true

    if(form.endQuotation < startQuotationReference.format('YYYY-MM-DDTHH:mm')) return true

    return form.endQuotation > startQuotationReference.add(30, 'days').format('YYYY-MM-DDTHH:mm')
  }, [form.endQuotation, startQuotationReference])

  const handleChange = useCallback((field, type) => {
    return (event) => {
      let value = ''
      let fieldValue

      if(type === 'text') {
        value = event.target.value
        fieldValue = value
      } else if(type === 'select') {
        value = event?.value
        fieldValue = { name: event?.label, id: event?.value }
      } else if(type === 'select-multi') {
        value = event.map(({ value}) => value)
        fieldValue = event.map(({ label, value }) => ({ name: label, id: value }))
      } else if (type === 'number') {
        value = event.target.value.replace(/\D/g, '')
        if(/^\d+$/.test(value)) {
          value = Number(value)
        } else {
          value = 0
        }
      }

      if(onFormChange) onFormChange({ [field]: fieldValue })

      setForm((prevState) => ({ ...prevState, [field]: value }))
    }
  }, [onFormChange])

  useEffect(() => {
    (async() => {
      setLoading(true)
      try {
        const [productsResponse, vehicleTypesResponse] = await Promise.all([
          App.api('fob::getProdutos'),
          App.api('ListVehicleTypes'),
        ])

        setProducts(productsResponse.results)
        setVehicleTypes(vehicleTypesResponse.results)
      } catch (error) {
        setProducts([])
        setVehicleTypes([])
      } finally {
        setLoading(false)
      }
    })()
  }, [App])

  if(loading) return <App.LoadingComponent />

  return (
    <div className="f g2 w100">
      <div className="f g1 f-column f1">
        <div className="w100 f g1">
          <Input
            clearable
            className="w100"
            label={t(lang, 'startQuotation')}
            error={startQuotationError}
            onChange={handleChange('startQuotation', 'text')}
            type="datetime-local"
            value={form.startQuotation ?? ''}
            min={moment().format('YYYY-MM-DDTHH:mm')}
            max={moment().add(30, 'days').format('YYYY-MM-DDTHH:mm')}
          />
          <Input
            clearable
            className="w100"
            error={endQuotationError}
            label={t(lang, 'endQuotation')}
            onChange={handleChange('endQuotation', 'text')}
            type="datetime-local"
            value={form.endQuotation ?? ''}
          />
        </div>
        <div className="w100">
          <Select
            clearable
            searchable
            className="w100"
            error={!form.product}
            label={t(lang, 'product')}
            onChange={handleChange('product', 'select')}
            options={products}
            value={form.product}
          />
        </div>
        <div className="w100 f g1">
          <Select
            clearable
            multi
            searchable
            className="w100"
            error={form.vehicleTypes.length < 1}
            label={t(lang, 'vehicleTypes')}
            onChange={handleChange('vehicleTypes', 'select-multi')}
            options={vehicleTypes}
            value={form.vehicleTypes}
          />
          <Input
            clearable
            className="w100"
            error={form.minimumVehicleQuantity < 1}
            label={t(lang, 'minimumVehicleQuantity')}
            onChange={handleChange('minimumVehicleQuantity', 'text')}
            type="number"
            value={formatNumber(form.minimumVehicleQuantity ?? 0)}
          />
        </div>
      </div>
    </div>
  )
}
