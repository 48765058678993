import { DaterangePickerFilter } from './fields/DaterangePickerFilter';
import { InputFilter } from './fields/InputFilter';
import { InputMultiFilter } from './fields/InputMultiFilter';
import { SelectFilter } from './fields/SelectFilter';

export const FieldFilter = ({ filter, onChange }) => {
  const { kind, label, options, settings, value } = filter;

  switch (kind) {
    case 'daterange':
      return (
        <DaterangePickerFilter
          label={label}
          onChange={onChange}
          settings={settings}
          value={value}
        />
      );
    case 'input':
      // Para campos input, usar o InputMultiFilter
      return (
        <InputFilter
          label={label}
          onChange={onChange}
          settings={settings}
          value={value}
        />
      );
    case 'multi-input':
      // Para campos input, usar o InputMultiFilter
      return (
        <InputMultiFilter
          label={label}
          onChange={onChange}
          settings={settings}
          value={value}
          maxItems={500} // Limite máximo de 500 itens
        />
      );
    case 'select':
    case 'multi-select':
      return (
        <SelectFilter
          kind={kind}
          label={label}
          onChange={onChange}
          options={options}
          settings={settings}
          value={value}
        />
      );
    default:
      return null;
  }
};
