import React, { useCallback, useContext, useMemo, useState } from "react"

import { AppContext } from "../../../../../App"
import { t } from "../../../../../f"
import { Modal } from "../../../../../c"
import { ItineraryStep } from "./ItineraryStep"
import { QuotationStep } from "./QuotationStep"
import { ShippingCompaniesStep } from "./ShippingCompaniesStep"
import { ObservationsStep } from "./ObservationsStep"
import { SummaryStep } from "./SummaryStep"
import moment from "moment"

const STEPS = [
  {
    name: 'itinerary',
    step: ({ form, lang, onFormChange }) => (
      <ItineraryStep form={form} lang={lang} onFormChange={onFormChange} />
    ),
    validation: (form) => !!form.itinerary,
  },
  {
    name: 'quotation',
    step: ({ form, lang, onFormChange }) => (
      <QuotationStep form={form} lang={lang} onFormChange={onFormChange} />
    ),
    validation: (form) => {
      const { startQuotation, endQuotation, product, minimumVehicleQuantity, vehicleTypes } = form

      if(!startQuotation || !endQuotation || !product || minimumVehicleQuantity < 1 || vehicleTypes.length < 1) {
        return false
      }

      if(startQuotation < moment().format('YYYY-MM-DDTHH:mm')) return false
      if(startQuotation > moment().add(30, 'days').format('YYYY-MM-DDTHH:mm')) return false

      if(endQuotation < startQuotation) return false
      if(endQuotation >  moment(startQuotation).add(30, 'days').format('YYYY-MM-DDTHH:mm')) return false

      return true
    },
  },
  {
    name: 'shippingCompanies',
    step: ({ form, lang, onFormChange }) => (
      <ShippingCompaniesStep form={form} lang={lang} onFormChange={onFormChange} />
    ),
    validation: (form) => form.shippingCompanies.length > 0,
  },
  {
    name: 'observations',
    step: ({ form, lang, onFormChange }) => <ObservationsStep form={form} lang={lang} onFormChange={onFormChange} />,
    validation: (form) => true,
  },
  {
    name: 'summary',
    step: ({ form, lang }) => <SummaryStep form={form} lang={lang} />,
    validation: (form) => true,
  },
]

const INITIAL_FORM = {
  itinerary: undefined,

  startQuotation: undefined,
  endQuotation: undefined,
  minimumVehicleQuantity: 0,
  product: undefined,
  vehicleTypes: [],

  shippingCompanies: [],

  observations: [],
}

export const CreateModal = ({ visible, lang, onClose, onFinish  }) => {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [form, setForm] = useState(INITIAL_FORM)

  const steps = useMemo(() => {
    return STEPS.map(({ name, validation }) => {
      return {
        label: t(lang.steps, `${name}Step`),
        validate: validation(form),
      }
    })
  }, [form, lang.steps])

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)

      const {
        itinerary: {
          id: itinerary,
          origin: { id: originId },
          destination: { id: destinationId },
        },
        startQuotation,
        endQuotation,
        minimumVehicleQuantity,
        product,
        vehicleTypes,
        shippingCompanies,
        observations,
      } = form

      const { status, results } = await App.api(
        'CreateQuotation',
        {
          itinerary,
          originId,
          destinationId,
          startQuotation,
          endQuotation,
          minimumVehicleQuantity: Number(minimumVehicleQuantity),
          product,
          vehicleTypes: vehicleTypes.map(({ id }) => id),
          shippingCompanies: shippingCompanies.map(({ name, id,cnpj }) => ({ name: name.trim(), id, cnpj: cnpj.trim()})),
          observations: observations.map(({ description, type }) => ({ description, type })),
        }
      )

      if(!status) return

      if(results.message) {
        App.toast.error(lang.error_message)

        throw new Error('Error')
      } else if(onFinish) {
        App.toast.success(lang.success_message)
        setForm(INITIAL_FORM)
        onFinish()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }, [App, form, onFinish])

  const handleChangeStep = useCallback((newStep) => {
    setStep(newStep)
  }, [])

  const handleFormChange = useCallback((data) => {
    setForm((prevState) => ({ ...prevState, ...data }))
  }, [])

  const content = useMemo(() => {
    const { name, step: Step } = STEPS[step] ?? STEPS[0]

    return (
      <Step
        form={form}
        lang={name === 'summary' ? lang : lang[`${name}Step`]}
        onFormChange={handleFormChange}
      />
    )
  }, [form, handleFormChange, lang, step])

  if(!visible) return <></>

  return(
    <Modal
      loading={loading}
      largeWidth
      title={t(App.lang.bid.createModal, 'title')}
      steps={steps}
      onStepChange={handleChangeStep}
      contentHight={400}
      onFinish={handleSubmit}
      closeOnFinish={false}
      onClose={onClose}
    >
      {content}
    </Modal>
  )
}
