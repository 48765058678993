import { useCallback, useContext, useMemo, useState } from 'react'
import { AppContext } from '../App'
import * as c from '../c'
import * as f from '../f'

export default function AnexarTicketModal({ cota, onClose, onFinish }){
  const App = useContext(AppContext)
  const lang = useMemo(() => ({
    ...App.lang.global,
    ...App.lang.transportador
  }), [App.lang.global, App.lang.transportador])

  const [loading, setLoading] = useState(null)
  const [data, setData] = useState({})

  const handleSubmit = useCallback(async () => {
    let doc = "";

    if (data?.pdf) {
      doc = data.pdf.split(',')[1]??data.pdf;
    } else {
      doc = await f.fileToBase64( data.images[0].file )
    }

    setLoading(true)


    const { status } = await App.api('recordDocuments::gravaDescarga', {
      id_uocc: cota.ID_UOCC ?? cota.ticketId,
      qt_peso: data.qt_peso,
      dt_peso: data.dt_peso,
      ds_obs: data.ds_obs,
      tp_ext: data.images[0].name.split('.').pop(),
      imagem: doc,
      ds_tag: 'ticket_descarga',
    })

    setLoading(false)

    if(onFinish) onFinish()

    return status
  }, [App, cota.ID_UOCC, cota.ticketId, data.ds_obs, data.dt_peso, data.images, data.qt_peso, onFinish])

  return(
    <c.Modal
      largeWidth
      loading={loading}
      onClose={onClose}
      onFinish={handleSubmit}
      title={'Anexar ticket'}
      VIAIconProps={{ type: 'ticket' }}
      validate={!!data.qt_peso && !!data.dt_peso && !!data.images?.length}
    >
      <div className='f g1'>
        <c.Span label={lang.veiculo} value={cota.ID_VCL} />
        <c.Span label={lang.peso_origem} value={cota.QT_SAI} />
        <c.Span label={lang.nfe} value={cota.LST_NFE} />
        <c.Span label={lang.cte} value={cota.LST_CTE} />
        <c.Span label={lang.produto} value={cota.NM_PRO} />
        <c.Span label={lang.destino} value={cota.NM_DST} />
      </div>

      <div className='f g1'>
        <div className='w1-2 f g1 f-column w50'>
          <div className='f g1 f-column'>
            <div className='f g1'>
              <c.Input label={"Peso descarga"} type="number" error={!data.qt_peso} required value={data.qt_peso} onChange={e => setData({...data, qt_peso: e.target.value})} className="w1-3" />
              <c.Span label={"Quebra (%)"} value={<>{Number(data.qt_peso??0) - Number(cota.QT_SAI??0)} ({(Number(data.qt_peso??0) - Number(cota.QT_SAI??0)) / Number(cota.QT_SAI??1) * 100})</>} className="w1-3" />
              <c.Input label={"Data/hora descarga"} error={!data.dt_peso} required type="date" value={data.dt_peso} onChange={e => setData({...data, dt_peso: e.target.value})} className="w1-3" />
            </div>
            <c.Input label={lang.observacao} value={data.ds_obs} onChange={e => setData({...data, ds_obs: e.target.value})} />
          </div>

          <c.Upload accept={{"image/png": ['.png'], "image/jpeg": ['.jpg'],
               "application/pdf": ['.pdf'],
            }}
            label="Arquivo aceito: PDF, JPG, e PNG. Máximo de 1 arquivo." maxFiles={1} files={data.images??[]}
            onChange={async e => {

              let pdf = null;

              if (e?.[0]?.file?.type === "application/pdf") {
                try {
                  const fileContent = await f.readFile(e?.[0]?.file);
                  pdf = fileContent;
                } catch (error) {
                  console.error('Erro ao ler arquivo PDF:', error);
                }
              }

              setData({
                ...data,
                images: e,
                pdf: pdf
              });

            }}
            className='w100' />
        </div>

        <div className='w2-3'>
          <c.Span label={"Pré-visualização"} className='center-v'
            value={!!data?.images?.[0]
                    ? (data?.images?.[0].file?.type === "application/pdf" ?
                      <c.Frame alt="Preview" className="w100" ><c.PreviewPDF file={data.pdf} /></c.Frame>
                      : <img src={data?.images?.[0]?.preview} alt="Preview" className='w100' style={{maxHeight: 400}} />
                    )
                    : <div className='p10'>Selecione um arquivo para visualizar</div>
                  }
          />
        </div>
      </div>
    </c.Modal>
  )
}
