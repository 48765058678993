import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  MdAdd as AddIcon,
  MdDelete as DeleteIcon,
  MdDeleteSweep  as DeleteAllIcon,
  MdPlaylistAdd as AddAllIcon,
} from "react-icons/md"

import { AppContext } from "../../../../../../App"
import { Select, Table } from "../../../../../../c"
import { formatCnpj, t } from "../../../../../../f"

export const ShippingCompaniesStep = ({ form: initialForm, lang, onFormChange }) => {
  const App = useContext(AppContext)

  const [loading, setLoading] = useState(true)
  const [shippingCompanies, setShippingCompanies] = useState([])
  const [list, setList] = useState(initialForm?.shippingCompanies ?? [])
  const [form, setForm] = useState({ shippingCompany: undefined })

  const handleChangeForm = useCallback((newShippingCompanies) => {
    if(onFormChange) {
      onFormChange({
        ...initialForm,
        shippingCompanies: newShippingCompanies
      })
    }
  }, [initialForm, onFormChange])

  const handleChange = useCallback((field) => {
    return (option) => {
      const { value } = option ?? {}

      setForm((prevState) => ({ ...prevState, [field]: value }))
    }
  }, [])

  const handleAdd = useCallback(() => {
    setLoading(true)

    const shippingCompany = shippingCompanies.find(({ id }) => id === form.shippingCompany)

    if(shippingCompany) {
      handleChangeForm([...initialForm.shippingCompanies, shippingCompany])
      setList((prevState) => [...prevState, shippingCompany])
      setForm({ shippingCompany: undefined })
    }

    setLoading(false)
  }, [form.shippingCompany, handleChangeForm, initialForm.shippingCompanies, shippingCompanies])

  const handleAddAll = useCallback(() => {
    setLoading(true)

    handleChangeForm(shippingCompanies)
    setList(shippingCompanies)
    setForm({ shippingCompany: undefined })

    setLoading(false)
  }, [handleChangeForm, shippingCompanies])

  const shippingCompanyOptions = useMemo(() => {
    const listIds = list.map(({ id }) => id)

    return shippingCompanies
      .filter(({ id }) => !listIds.includes(id))
      .map(({ cnpj, name, id }) => ({
        label: `${formatCnpj(cnpj)} - ${name}`,
        value: id,
      }))
  },[list, shippingCompanies])

  const columns = useMemo(() => {
    const handleRemoveAll = () => {
      handleChangeForm([])
      setList([])
    }
    const removeAll = (
      <button className="danger" onClick={handleRemoveAll}>
        <DeleteAllIcon size={24} title={t(lang, 'removeAll')} />
      </button>
    )

    return [
      [t(lang, 'cnpj'), 'cnpj'],
      [t(lang, 'shippingCompany'), 'name'],
      [removeAll, 'actions',{},'force-fit'],
    ]
  },[handleChangeForm, lang])

  const data = useMemo(() => {
    const handleRemove = (id) => {
      return () => {
        if(list.length < 1) return

        const newList = list.filter((item) => item.id !== id)

        handleChangeForm(newList)
        setList(newList)
      }
    }

    return list.map(({ cnpj, name, id }) => {
        const actions = (
          <button className="danger" onClick={handleRemove(id)}>
            <DeleteIcon size={24} title={t(lang, 'remove')} />
          </button>
        )

        return { cnpj: formatCnpj(cnpj), name, id, actions }
      },
      [],
    )
  }, [handleChangeForm, lang, list])

  useEffect(() => {
    (async() => {
      try {
        setLoading(true)
        const { results } = await App.api('transportadoras::get')

        setShippingCompanies(
          results.map(
            ({ NM_TRP: name, NR_CNPJTRP: cnpj, ID_PES: id }) => ({ name, cnpj, id })
          )
        )
      } catch (error) {
        setShippingCompanies([])
      } finally {
        setLoading(false)
      }
    })()
  }, [App])

  if(loading) return <App.LoadingComponent />

  return (
    <div className="f g2 f-column w100">
      <div className="f g1">
        <Select
          clearable
          searchable
          className="w100"
          label={t(lang, 'shippingCompany')}
          onChange={handleChange('shippingCompany')}
          options={shippingCompanyOptions}
          value={form.shippingCompany}
          defaultValue={form.shippingCompany}
        />

        <button className="success" onClick={handleAdd}>
          <AddIcon size={30} title={t(lang, 'add')} />
        </button>

        <button className="success" onClick={handleAddAll}>
          <AddAllIcon size={30} title={t(lang, 'addAll')} />
        </button>
      </div>

      <div className="f g1 f1">
        <Table
          isSub
          columns={columns}
          data={data}
          fixedPerPage={10}
          loading={loading}
        />
      </div>
    </div>
  )
}
